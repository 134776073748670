<template>
    <div :class="getWrapperStyleInline">
        <div class="flex" :class="getRequiredStyleInline">
            <p v-if="label" :class="[getLabelStyleInline, 'text-sm font-medium text-gray-700']">{{ label }}</p>
            <span v-if="required" :class="starStyle">*</span>
        </div>
        <p v-if="description" class="text-sm text-gray-500 my-4">
            {{ description }}
        </p>
        <VueTelInput
            ref="telInput"
            v-model="phone.formatted"
            mode="international"
            :validCharactersOnly="true"
            :defaultCountry="defaultRegion"
            :inputOptions="{ placeholder }"
            :disabled="disabled"
            :class="inputStyle"
            @input="onInput"
        />
        <p v-if="localErrorMessage" class="mt-2 text-sm text-red-600">{{ localErrorMessage }}</p>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue"
import { VueTelInput } from "vue-tel-input"
import { Phone, PhoneNumber } from "@/shared/models/phone"
import { CountryCode } from "libphonenumber-js"

interface Props {
    modelValue?: Phone
    label?: string
    description?: string
    placeholder?: string
    defaultRegion?: string
    inline?: boolean
    invalid?: boolean
    disabled?: boolean
    required?: boolean
    errorMessage?: string
}

const props = withDefaults(defineProps<Props>(), {
    defaultRegion: "US",
    invalid: false,
    required: false,
    placeholder: "Phone Number",
})

const emit = defineEmits(["update:modelValue", "onKeydown", "update:invalid", "onchange"])

const phoneNumber = computed({
    get() {
        return props.modelValue as Phone
    },
    set(value: Phone) {
        emit("update:modelValue", value)
        emit("onchange", value)
    },
})
const localInvalid = ref(props.invalid)
const localErrorMessage = ref(props.errorMessage)

const phone = ref<PhoneNumber>(setDefaultModelValue())
const telInput = ref()
const telInputWidth = ref("0px")
const placeholder = ref(props.placeholder)

const getWrapperStyleInline = computed(() => (props.inline ? "flex items-center" : ""))
const getLabelStyleInline = computed(() => (props.inline ? "w-2/5" : "mb-1"))
const getRequiredStyleInline = computed(() => (props.inline ? "gap-2" : ""))
const inputStyle = computed(() => [
    props.disabled ? "opacity-50 cursor-not-allowed" : "",
    localInvalid.value
        ? "border-2 border-red-300  placeholder-red-300 ring-1 ring-red-300 focus:border-2 focus:border-red-300"
        : "",
])
const starStyle = computed(() => [localInvalid.value ? "text-red-600" : "text-gray-700", "pl-1"])

// This function is only called when the input value is changed and not when the modelValue is modified in the parent component
function onInput() {
    disableValidationError()
    const number = telInput.value?.phone || ""
    const phoneObject = telInput.value?.phoneObject

    const newPhone = {
        number,
        country: phoneObject?.countryCode as CountryCode,
    }

    // if there is more than 1 digit in the phoneObject number, we set the formatted number
    if (phoneObject?.number) {
        newPhone.number = phoneObject.formatted
    }
    phoneNumber.value = newPhone

    emit("onKeydown")
}

function disableValidationError() {
    localErrorMessage.value = ""
    localInvalid.value = false
}

function setDefaultModelValue() {
    return {
        countryCode: props.modelValue?.country || props.defaultRegion,
        number: props.modelValue?.number || "",
        country: { name: "", iso2: props.modelValue?.country || props.defaultRegion, dialCode: "" },
        countryCallingCode: "",
        formatted: props.modelValue?.number || "",
        nationalNumber: "",
        valid: false,
    }
}

onMounted(() => {
    telInputWidth.value = `${telInput.value.$el.offsetWidth}px`
})

watch(
    () => props.errorMessage,
    (newVal) => {
        localErrorMessage.value = newVal
        localInvalid.value = props.invalid
    }
)

watch(
    () => props.invalid,
    () => {
        localInvalid.value = props.invalid
    }
)

watch(
    () => props.defaultRegion,
    () => {
        telInput.value.activeCountryCode = props.defaultRegion
    }
)

watch(
    () => props.modelValue,
    () => {
        phone.value = setDefaultModelValue()
        if (!props.modelValue?.number && props.invalid) {
            localErrorMessage.value = props.errorMessage
            localInvalid.value = props.invalid
        }
    },
    {
        deep: true,
    }
)
</script>

<style scoped>
:deep(.vti__selection) {
    @apply gap-1;
}
:deep(.vti__search_box) {
    @apply w-full m-0;
}
:deep(.vti__input) {
    @apply p-2 rounded-lg placeholder-gray-400;
}
:deep(.vue-tel-input:focus-within) {
    @apply border-primary-color-500 shadow-none ring-1 ring-primary-color-500;
}
:deep(.vue-tel-input) {
    @apply border border-gray-300 rounded-lg shadow-sm w-full;
}
:deep(.vti__input.vti__search_box) {
    @apply rounded-none;
}
:deep(.vti__dropdown) {
    @apply rounded-lg;
}
:deep(.vti__dropdown:hover, .vti__dropdown.open) {
    @apply bg-primary-color-100;
}
:deep(.vti__dropdown-list.below) {
    @apply top-10 rounded-lg;
    width: v-bind(telInputWidth);
}

:deep(input:where(:not([type])):focus) {
    @apply ring-0 border-0;
}
</style>
