<template>
    <GgmsLoading v-show="isTaskLoading" />
    <div v-show="!isTaskLoading" ref="form" class="flex flex-col gap-y-[30px]">
        <div class="flex gap-x-2.5">
            <GgmsInput
                v-model="task.displayName"
                ref="firstInput"
                class="grow"
                name="task-name"
                label="Task Name"
                :overwriteShowErrors="true"
                :invalid="isInvalid('displayname', error)"
                :errorMessage="hasErrorMessage('displayname', error)"
            />
            <component
                v-if="!isForWorkflow"
                :is="getCheckCircleIconType"
                class="h-9 w-9 mt-7"
                :class="[getStatusIconColor, getStatusCursorType]"
                @click="changeStatusOfTask"
            />
        </div>
        <div class="flex max-md:flex-wrap gap-4">
            <GgmsDropdown
                v-model="task.type"
                :options="taskTypes"
                name="task-type"
                label="Task Type"
                optionLabel="value"
                optionValue="attribute"
                placeholder="Select task type"
                :overwriteShowErrors="true"
                :invalid="isInvalid('type', error)"
                :errorMessage="hasErrorMessage('type', error)"
                class="w-full"
            />
            <GgmsDropdown
                v-model="task.collectionId"
                :options="collections"
                name="task-queue"
                label="Task List"
                optionLabel="displayName"
                optionValue="_id"
                placeholder="Select task list"
                class="w-full"
                :overwriteShowErrors="true"
            />
        </div>
        <GgmsDropdown
            v-model="task.agentId"
            :options="cachedAgents"
            :overwriteShowErrors="true"
            name="agents"
            label="Assigned to"
            optionLabel="fullName"
            optionValue="_id"
            placeholder="Select agent"
            :invalid="isInvalid('agentid', error)"
            :errorMessage="hasErrorMessage('agentid', error)"
            class="w-full"
        />
        <div>
            <div class="flex max-sm:flex-wrap gap-4" :class="isForWorkflow ? 'flex-col gap-y-4' : 'flex-row'">
                <div>
                    <span>Due date</span>
                    <div class="flex max-[320px]:flex-wrap gap-4">
                        <GgmsInput
                            v-model="queueDate"
                            type="date"
                            :iconStart="CalendarIcon"
                            :invalid="isInvalid('queueDate', error)"
                            :errorMessage="hasErrorMessage('queueDate', error)"
                            :overwriteShowErrors="true"
                            class="w-[150px]"
                        ></GgmsInput>
                        <GgmsInput
                            v-model="queueTime"
                            type="time"
                            :iconStart="ClockIcon"
                            :invalid="isInvalid('queueTime', error)"
                            :errorMessage="hasErrorMessage('queueTime', error)"
                            :overwriteShowErrors="true"
                            class="w-36"
                        ></GgmsInput>
                    </div>
                </div>
                <div>
                    <span>Set reminder</span>
                    <GgmsDropdown
                        v-model="task.reminder"
                        :options="reminderOptions"
                        :overwriteShowErrors="true"
                        name="reminder"
                        optionLabel="value"
                        optionValue="attribute"
                        placeholder="Select reminder"
                        class="w-full"
                    />
                </div>
            </div>
        </div>

        <div>
            <GgmsCheckBox
                v-model="task.shouldRemoveIfNotCompleted"
                name="remove-if-not-completed"
                label="Remove task if not completed in (after Due Date)"
                :isLabelClickable="true"
            />
            <div class="flex gap-x-4 pt-3">
                <GgmsInput
                    v-model="task.unitsOfTimeToRemove"
                    :disabled="!task.shouldRemoveIfNotCompleted"
                    :overwriteShowErrors="true"
                />
                <GgmsDropdown
                    v-model="task.typeOfTimeToRemove"
                    :options="notCompletedOptions"
                    :overwriteShowErrors="true"
                    :disabled="!task.shouldRemoveIfNotCompleted"
                    optionLabel="value"
                    optionValue="attribute"
                    placeholder="Select time"
                    class="w-full"
                />
            </div>
        </div>

        <GgmsMultiSelect
            v-model="tag"
            label="Add Tags"
            placeholder="+ Add Tag"
            display="chip"
            :options="tags"
            :overwriteShowErrors="true"
            optionLabel="displayName"
            :invalid="isInvalid('tags', error)"
            :errorMessage="hasErrorMessage('tags', error)"
            classes="w-full"
        />
        <GgmsTextarea
            v-model="task.description"
            placeholder="Add description"
            label="Task description"
            :rows="5"
            :invalid="isInvalid('description', error)"
            :errorMessage="hasErrorMessage('description', error)"
            :overwriteShowErrors="true"
        />
        <GgmsTextarea
            v-model="task.notes"
            placeholder="Add notes"
            label="Notes"
            :rows="5"
            :overwriteShowErrors="true"
        />

        <GgmsDropdown
            v-model="selectedDomain"
            :options="domainOptions"
            label="Domain"
            name="domain"
            :isShowClear="true"
            optionLabel="url"
            optionValue="_id"
            placeholder="Select Domain"
            class="w-full"
            :invalid="isInvalid('domain', error)"
            :error-message="hasErrorMessage('domain', error)"
            :overwriteShowErrors="true"
        />
    </div>
</template>
<script setup lang="ts">
import { computed, ref, reactive, watch, onMounted, nextTick, onUpdated } from "vue"
import GgmsInput from "@/components/GgmsInput.vue"
import GgmsTextarea from "@/components/GgmsTextarea.vue"
import GgmsCheckBox from "@/components/GgmsCheckBox.vue"
import GgmsDropdown from "@/components/GgmsDropdown.vue"
import GgmsMultiSelect from "@/components/GgmsMultiSelect.vue"
import { CalendarIcon, ClockIcon, CheckCircleIcon as CheckCircleIconOutline } from "@heroicons/vue/outline"
import { CheckCircleIcon } from "@heroicons/vue/solid"
import { useTaskStore } from "@/stores/task"
import { useAgentsStore } from "@/stores/agents"
import { useGridCollectionStore } from "@/stores/grid-collection"
import { useAuthStore } from "@/stores/auth"
import { useAgencyStore } from "@/stores/agency"
import { useWorkflowStore } from "@/stores/workflow"
import { useTagStore } from "@/stores/tag"
import {
    isInvalid,
    hasErrorMessage,
    isAdmin,
    getDomainId,
    getTaskTypes,
    getTaskReminderOptions,
    formatDateToIso,
} from "@/shared/utils/helpers"
import { Task } from "@/shared/models/task"
import { Tag } from "@/shared/models/tag"
import GgmsLoading from "../GgmsLoading.vue"
import { Agent } from "@/shared/models/agent"

const taskStore = useTaskStore()
const agentStore = useAgentsStore()
const authStore = useAuthStore()
const agencyStore = useAgencyStore()
const gridCollectionStore = useGridCollectionStore()
const workflowStore = useWorkflowStore()
const tagStore = useTagStore()

interface Props {
    modalAction?: string
    taskId?: string
    contactId?: string
    isNotEditableByUser?: boolean
    isCreateTaskModalOpen?: boolean
    isForWorkflow?: boolean
    isUpdateTask?: boolean
    taskValue?: Task
    errorValue?: { field: string; message: string }
}

const props = defineProps<Props>()
const emit = defineEmits(["update:task"])

const form = ref()
const error = ref()
const isTaskLoading = ref(true)
const queueDate = ref("")
const queueTime = ref("")
const firstInput = ref()
const isFirstLoad = ref(true)

const tags = ref<Tag[]>([])
const tag = ref<Tag[]>([])

const taskTypes = ref(getTaskTypes())
const reminderOptions = ref(getTaskReminderOptions())

const cachedAgents = computed(() =>
    agentStore.tableState.data.filter((agent: Agent) => agent.status !== "pending" && agent.fullName)
)
const currentUser = computed(() => authStore.currentUser)
const collections = computed(() => gridCollectionStore.collectionTableState.data)

const task: Task = reactive({
    _id: "",
    displayName: "",
    description: "",
    when: {
        startAt: "",
        timezone: "America/Los_Angeles",
    },
    reminder: reminderOptions.value[0].attribute,
    shouldRemoveIfNotCompleted: true,
    agent: {
        _id: currentUser?.value?._id,
        fullName: currentUser?.value?.fullName,
    },
    agentId: currentUser?.value?._id,
    collection: {
        _id: "",
        displayName: "",
    },
    type: "",
    collectionId: "",
    status: "",
    notes: "",
    domainId: "",
    tags: [],
    unitsOfTimeToRemove: "30",
    typeOfTimeToRemove: "days",
})

const domainOptions = computed(() =>
    agencyStore.agency.agencyDomains.map((domain) => ({ url: domain.url, _id: domain._id }))
)
const notCompletedOptions = [
    { value: "days", attribute: "days" },
    { value: "hours", attribute: "hours" },
    { value: "minutes", attribute: "minutes" },
]
const selectedDomain = ref(initializeDomain())

const isTaskCompleted = computed(() => task.status === "completed")
const getCheckCircleIconType = computed(() => (isTaskCompleted.value ? CheckCircleIcon : CheckCircleIconOutline))
const getStatusIconColor = computed(() => {
    if (isTaskCompleted.value) {
        return props.isUpdateTask
            ? "fill-primary-color-600 text-white"
            : "fill-primary-color-600 text-white hover:fill-primary-color-700"
    } else {
        return props.isUpdateTask ? "text-gray-400 hover:text-gray-500" : "text-gray-400"
    }
})
const getStatusCursorType = computed(() => {
    if (props.isNotEditableByUser) {
        return "cursor-not-allowed"
    }
    if (props.modalAction === "edit") {
        return "cursor-pointer"
    }
    return ""
})

function setQueueDateAndTime(date: string) {
    const isoDateInAgencyTimezone = formatDateToIso(new Date(date))
    const [datePart, timePart] = isoDateInAgencyTimezone.split("T")
    queueDate.value = datePart
    queueTime.value = timePart.replace("Z", "")
}

function setTaskStartDate() {
    try {
        const date = new Date(queueDate.value)
        const hours = Number(queueTime.value.split(":")[0])
        const minutes = Number(queueTime.value.split(":")[1])

        if (task.when === undefined) {
            task.when = {
                startAt: formatDateToIso(new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes)),
                timezone: "America/Los_Angeles",
            }
        } else {
            // Manually set the date and time as we don't want other conversions
            const year = date.getFullYear()
            const month = String(date.getMonth() + 1).padStart(2, "0")
            const day = String(date.getDate()).padStart(2, "0")
            const hour = String(hours).padStart(2, "0")
            const minute = String(minutes).padStart(2, "0")
            task.when.startAt = `${year}-${month}-${day}T${hour}:${minute}:00.000Z`
        }
    } catch (error) {
        return
    }
}

async function changeStatusOfTask() {
    if (props.isNotEditableByUser) {
        return
    }

    if (props.taskId) {
        if (props.modalAction === "edit") {
            const status = isTaskCompleted.value ? "pending" : "completed"
            await taskStore.updateTaskStatus(props.taskId, status)
            task.status = status
            taskStore.getTasks()
        }
    }
}

function initializeDomain() {
    const domain = getDomainId()
    task.domainId = domain
    return domain === "all" ? null : domain
}

function scrollToErrorField() {
    if (props.errorValue && !props.errorValue.field) {
        return
    }
    const errorField = form.value.querySelector(`[name="${error.value.field}"]`) || form.value.querySelector("input")
    if (errorField) {
        errorField.scrollIntoView({ behavior: "smooth" })
    }
}

onUpdated(() => {
    nextTick(() => {
        const inputElement = firstInput.value?.$el.querySelector("input")
        if (inputElement && isFirstLoad.value) {
            inputElement.focus()
            isFirstLoad.value = false
        }
    })
})

onMounted(async () => {
    gridCollectionStore.collectionType = "task"
    if (isAdmin() && !cachedAgents.value.length) {
        await agentStore.loadAgents()
    }
    //get tags for task
    tags.value = await tagStore.getTagsForTasks()

    if (props.isForWorkflow) {
        setQueueDateAndTime(new Date())
        if (workflowStore.modelValue) {
            Object.assign(task, workflowStore.modelValue)
            if (workflowStore.modelValue.domainId) {
                task.domainId = workflowStore.modelValue.domainId
            } else {
                initializeDomain()
            }
            if (task.when) {
                setQueueDateAndTime(new Date(task.when.startAt))
            }
            if (workflowStore.modelValue.tags) tag.value = workflowStore.modelValue.tags
            task.unitsOfTimeToRemove = workflowStore.modelValue.unitsOfTimeToRemove || "30"
            task.typeOfTimeToRemove = workflowStore.modelValue.typeOfTimeToRemove || "days"
        }
        isTaskLoading.value = false
    } else if (props.taskId) {
        try {
            isTaskLoading.value = true
            const response = await taskStore.getTask(props.taskId)
            task.displayName = response.task.displayName
            task.description = response.task.description
            task.when = {
                startAt: response.task.when?.startAt,
                timezone: response.task.when?.timezone || "America/Los_Angeles",
            }
            task.reminder =
                reminderOptions.value.find((option) => option.attribute === response.task.reminder)?.attribute ||
                "minutes_30"
            task.shouldRemoveIfNotCompleted = response.task.shouldRemoveIfNotCompleted || false
            task.agent = {
                _id: response.task.agent?._id,
                fullName: response.task.agent?.fullName,
            }
            if (response.task.agent._id) task.agentId = response.task.agent?._id
            task.type = taskTypes.value.find((option) => option.attribute === response.task.type)?.attribute || ""
            task.collection = {
                _id: response.task.collection?._id,
                displayName: response.task.collection?.displayName,
            }
            task.collectionId = response.task.collection?._id
            task.status = response.task.status
            task.notes = response.task.notes
            task.domainId = response.task.domainId || ""
            selectedDomain.value = domainOptions.value.find((option) => option._id === task?.domainId)?._id
            if (task.when) {
                setQueueDateAndTime(task.when.startAt)
            }
            if (response.task.tags) tag.value = response.task.tags
            task.unitsOfTimeToRemove = response.task.unitsOfTimeToRemove || "30"
            task.typeOfTimeToRemove = response.task.typeOfTimeToRemove || "days"
            isTaskLoading.value = false
        } catch (e) {
            console.log(`There was an error getting task with id: ${props.taskId}`, e)
        } finally {
            isTaskLoading.value = false
        }
    } else {
        isTaskLoading.value = false
    }
})

watch(
    () => agencyStore.selectedDomain,
    (newValue) => {
        selectedDomain.value = newValue._id === "all" ? "" : newValue._id
    }
)

watch(
    () => queueDate.value,
    () => {
        setTaskStartDate()
    }
)

watch(
    () => queueTime.value,
    () => {
        setTaskStartDate()
    }
)

watch(
    () => selectedDomain,
    () => {
        task.domainId = selectedDomain.value
    }
)

watch(
    () => task,
    () => {
        setTaskStartDate()
        emit("update:task", task)
    },
    { deep: true }
)

watch(
    () => tag,
    () => {
        task.tags = tag.value
    },
    { deep: true }
)

watch(
    () => props.errorValue,
    () => {
        if (!props.errorValue) {
            error.value = {
                field: "",
                message: "",
            }
            return
        }
        error.value = props.errorValue
        scrollToErrorField()
    }
)
</script>
