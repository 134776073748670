<template>
    <GgmsToasts />
    <GgmsSidebarAndHeader v-if="route?.meta?.requiresAuth">
        <router-view :key="selectedAgencyAndDomain" />
    </GgmsSidebarAndHeader>
    <router-view v-else />
</template>

<script lang="ts" setup>
import { computed, onMounted } from "vue"
import { useRoute, useRouter } from "vue-router"
import GgmsSidebarAndHeader from "@/components/GgmsSidebarAndHeader.vue"
import GgmsToasts from "@/components/GgmsToasts.vue"
import { useAgencyStore } from "@/stores/agency"
import { useAgencyThemeStore } from "@/stores/agency-theme"
import { setColorShades } from "@/shared/utils/color-helpers"
import { ToastService } from "@/shared/services/toast"
import { useAuthStore } from "@/stores/auth"
const route = useRoute()
const router = useRouter()
const agencyStore = useAgencyStore()
const agencyThemeStore = useAgencyThemeStore()
const authStore = useAuthStore()
const toastService = new ToastService()

const selectedAgencyAndDomain = computed(() => agencyStore.selectedAgencyAndDomain)

const storedPrimaryColor = localStorage.getItem("--primary-color")
const storedLinkColor = localStorage.getItem("--link-color")

if (storedPrimaryColor && storedPrimaryColor !== "{}") {
    setColorShades("--primary-color", storedPrimaryColor)
}

if (storedLinkColor && storedLinkColor !== "{}") {
    setColorShades("--link-color", storedLinkColor)
}

function handleRedirectErrorMessage() {
    const redirectErrorMessage = route.query as { errorMessage?: string }

    if (!redirectErrorMessage?.errorMessage) {
        return
    }
    toastService.addToast({
        type: "error",
        message: redirectErrorMessage?.errorMessage,
    })

    // After showing the error message, remove it from the url
    router.replace({ query: {} })
}

function initTracker() {
    const tracker = window.Tracker
    if (tracker) {
        tracker
            .init(
                process.env.TRACKER_API_KEY || "3fac75be-0b46-4cbf-a946-fa5a1b944731",
                process.env.VUE_APP_BASE_API_URL || "https://dev.api.crm.ggms.com",
                process.env.VUE_APP_BEACON_API_URL || "https://dev.beacon.crm.ggms.com",
                process.env.TRACKER_DOMAIN || ".dev.crm.ggms.com",
                process.env.TRACKER_SUBDOMAIN
            )
            .then(() => {
                console.log("GGMS Tracker initialized")
            })
    }
}

onMounted(async () => {
    // init tracker
    initTracker()
    try {
        const tracker = window.Tracker
        //get user id from cookies, cookie is named "user"
        const userData = document.cookie
            .split("; ")
            .find((row) => row.startsWith("user="))
            ?.split("=")[1]
        const user = userData ? JSON.parse(userData) : null

        if (tracker && user?._id) {
            await tracker.authorize({
                userId: user?._id,
            })
        }
    } catch (error) {
        console.error("Authorization on tracker error", error)
    }
    await agencyThemeStore.getAgencyThemeBySubdomain()
    handleRedirectErrorMessage()
})
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

#app {
    font-family: Inter, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.p-overlaypanel:after,
.p-overlaypanel:before {
    display: none;
}
</style>
