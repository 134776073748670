import { defineStore } from "pinia"
import { BulkImportData, Contact } from "@/shared/models/contact"
import { ContactService } from "@/shared/services/contact"
import { TableState } from "@/shared/models/table"
import { Tag } from "@/shared/models/tag"
import { ToastModel } from "@/shared/models/toast-model"
import { useTimelineStore } from "@/stores/timeline"
import { ToastService } from "@/shared/services/toast"
import { EditableAddressPayload, EditableEmail, EditablePhonePayload } from "@/shared/models/contact-information"
import { GgmsError } from "@/shared/services/client"
import { useGridCollectionStore } from "@/stores/grid-collection"
import { useSocketStore } from "@/stores/socket"
import { ACL } from "@/shared/models/role"

export const useContactsStore = defineStore("contacts", {
    state: () => ({
        tableState: {
            data: [],
            selectedData: [],
            currentPage: 0,
            lastPage: 0,
            pageLength: 50,
            numberOfPages: 0,
            total: 0,
            isAllSelected: false,
            isLoading: false,
            assignedAgentsACLs: [],
            firstIds: [],
        } as TableState<Contact>,
        contactService: new ContactService(),
        toastService: new ToastService(),
        contact: {} as Contact,
        timelineStore: useTimelineStore(),
        isLoading: false,
        gridCollectionStore: useGridCollectionStore(),
        socketStore: useSocketStore(),
        isAddContactModalOpen: false,
        sendAnywayDND: false,
        sendAnywayMP: false,
        haveAllDND: false,
        haveAllMP: false,
        isError: false,
    }),
    actions: {
        async loadContacts(isFromCollection = false) {
            try {
                this.tableState.isLoading = true

                let lastId = null
                let offset = null

                // If we're going to the next page, use keyset pagination.
                if (this.tableState.currentPage === (this.tableState?.lastPage ?? 0) + 1) {
                    lastId =
                        this.tableState.data.length > 0
                            ? this.tableState.data[this.tableState.data?.length - 1]._id
                            : null
                }
                // If we're jumping to a specific page, use offset-based pagination.
                else if (
                    this.tableState.currentPage !== (this.tableState?.lastPage ?? 0) + 1 &&
                    this.tableState.currentPage > 1
                ) {
                    offset = (this.tableState.currentPage - 1) * this.tableState.pageLength
                }

                if (!this.gridCollectionStore?.grid?.filtersGroups?.length && isFromCollection) {
                    this.tableState.data = []
                    this.tableState.total = 0
                    this.tableState.selectedData = []
                    this.tableState.numberOfPages = Math.ceil(1)
                    this.tableState.lastPage = this.tableState.currentPage
                    return
                }

                const filtersGroups = this.gridCollectionStore?.grid?.filtersGroups?.length
                    ? encodeURIComponent(JSON.stringify(this.gridCollectionStore.grid.filtersGroups))
                    : undefined

                const response = await this.contactService.getAll({
                    length: this.tableState.pageLength,
                    column: this.tableState.column,
                    order: this.tableState.order,
                    search: this.tableState.search,
                    filtersGroups,
                    lastId,
                    offset,
                })

                // Store the first ID of the new page.
                if (this.tableState.firstIds && this.tableState.currentPage) {
                    this.tableState.firstIds[this.tableState.currentPage - 1] = response.data[0]?._id
                }

                this.tableState.data = response.data
                this.tableState.total = response.total
                this.tableState.selectedData = []
                this.tableState.numberOfPages = Math.ceil(response.total / this.tableState.pageLength)
                this.tableState.lastPage = this.tableState.currentPage
            } finally {
                this.tableState.isLoading = false
            }
        },

        resetTableState() {
            this.tableState.data = []
            this.tableState.total = 0
            this.tableState.selectedData = []
            this.tableState.numberOfPages = Math.ceil(1)
        },

        async getContacts(searchString: string) {
            return this.contactService.searchContacts(searchString, undefined, undefined)
        },

        async getContactsGrid() {
            try {
                this.tableState.isLoading = true
                const response = await this.gridCollectionStore.getGrid("contacts")
                const createdAtFilter = response.data[0].filtersGroups
                    .flatMap((group) => group.filters)
                    .find((filter) => filter.field === "createdAt" && filter.rule === "isBetween")

                if (!createdAtFilter) {
                    await this.loadContacts()
                }
                return response
            } finally {
                this.tableState.isLoading = false
            }
        },

        async getAssignedAgentsACLs(ids: string[]) {
            try {
                if (!ids) {
                    return
                }
                this.tableState.isLoading = true
                this.tableState.ids = ids?.join(",") ?? ""
                const response = await this.contactService.getAssignedAgentsACLs(this.tableState.ids)
                this.tableState.assignedAgentsACLs = response
                return response
            } finally {
                this.tableState.isLoading = false
            }
        },

        async updateContactsGrid() {
            try {
                this.tableState.isLoading = true
                if (!this.gridCollectionStore.grid) return
                this.gridCollectionStore.grid.filtersGroups = this.gridCollectionStore.filtersGroups || []

                const response = await this.gridCollectionStore.updateGrid("contacts", this.gridCollectionStore.grid)
                this.gridCollectionStore.gridResponse = response
                this.gridCollectionStore.grid = this.gridCollectionStore.gridResponse.grid
                this.gridCollectionStore.filtersGroups = this.gridCollectionStore.grid.filtersGroups

                await this.loadContacts()
            } finally {
                this.tableState.isLoading = false
            }
        },

        async clearFilters() {
            this.gridCollectionStore.grid.filtersGroups = []
            this.gridCollectionStore.filtersGroups = []

            this.updateContactsGrid()
        },

        addContact(data: Partial<Contact>) {
            return this.contactService.addContact(data)
        },

        async updateContact(id: string, data: Partial<Contact>, toastMessage?: string) {
            try {
                const response = await this.contactService.updateContact(id, data)
                if (!response) {
                    return
                }
                this.contact = response.contact

                if (toastMessage) {
                    this.toastService.addToast({
                        type: "success",
                        message: toastMessage,
                    })
                }

                return response
            } catch (error) {
                const err = error as GgmsError
                this.isError = true
                if (err.code === "ValidationError") {
                    this.toastService.addToast({
                        type: "error",
                        message: err.fullMessage ?? err.message,
                    })
                }
            }
        },

        async getOne(contactId: string) {
            try {
                this.timelineStore.isTimelineLoading = true
                const response = await this.contactService.getOne(contactId)
                if (!response) return

                this.contact = {
                    ...response.contact,
                    isUnknownContact: false,
                }
            } finally {
                this.timelineStore.isTimelineLoading = false
            }
        },

        bulkImport(payload: BulkImportData) {
            return this.contactService.bulkImport(payload)
        },

        async bulkUpdateTagOnContact(contactIds: string[], toAdd: Tag[], toRemove: Tag[], all: boolean) {
            const { job } = await this.contactService.bulkUpdateTagOnContact(contactIds, toAdd, toRemove, all)
            this.socketStore.addJob(job)
            this.contact.tags = this.contact?.tags?.concat(toAdd)
            this.contact.tags = this.contact?.tags?.filter((tag) => !toRemove.find((t) => t._id === tag._id))
        },

        async bulkUpdatePermissionsOnContact(
            contactIds: string[],
            toAdd: ACL[],
            toRemove: string[],
            listsToAdd: ACL[],
            listsToRemove: string[],
            all: boolean
        ) {
            await this.contactService.bulkUpdatePermissionsOnContact(
                contactIds,
                toAdd,
                toRemove,
                listsToAdd,
                listsToRemove,
                all
            )
        },

        async searchContacts(search: string, hasPhone: undefined | boolean, limit: undefined | number) {
            try {
                this.isLoading = true
                const contacts = await this.contactService.searchContacts(search, hasPhone, limit)
                return contacts
            } finally {
                this.isLoading = false
            }
        },

        async updateContactsEmail(contactId: string, emails: EditableEmail[]) {
            try {
                const res = await this.contactService.updateContactsEmail(contactId, emails)
                if (res) {
                    this.toastService.addToast({
                        type: "success",
                        message: "Email(s) updated successfully",
                    })
                    return res
                }
            } catch (error) {
                const err = error as GgmsError
                if (err.code === "ValidationError") {
                    this.toastService.addToast({
                        type: "error",
                        message: err.message,
                    })
                }
            }
        },

        async updateContactsPhone(contactId: string, phones: EditablePhonePayload[]) {
            try {
                const res = await this.contactService.updateContactsPhone(contactId, phones)
                if (res) {
                    this.toastService.addToast({
                        type: "success",
                        message: "Phone(s) updated successfully",
                    })
                    return res
                }
            } catch (error) {
                const err = error as GgmsError
                if (err.code === "ValidationError") {
                    this.toastService.addToast({
                        type: "error",
                        message: err.message,
                    })
                }
            }
        },

        async updateContactsAddress(contactId: string, addresses: EditableAddressPayload[]) {
            try {
                const res = await this.contactService.updateContactsAddress(contactId, addresses)
                if (res) {
                    this.toastService.addToast({
                        type: "success",
                        message: "Address(es) updated successfully",
                    })
                    return res
                }
            } catch (error) {
                const err = error as GgmsError
                if (err.code === "ValidationError") {
                    this.toastService.addToast({
                        type: "error",
                        message: err.message,
                    })
                }
            }
        },

        async updateStage(stage: Tag) {
            await this.contactService.updateStage(this.contact._id, stage)
            this.contact.stage = stage
        },

        async shareContact(contactId: string, agentsEmails: string[], permissionType: string) {
            try {
                const res = await this.contactService.shareContact(contactId, agentsEmails, permissionType)
                if (res) {
                    this.toastService.addToast({
                        type: "success",
                        message: "Contact shared successfully",
                    })
                    return res
                }
            } catch (error) {
                const err = error as GgmsError
                if (err.code === "ValidationError") {
                    this.toastService.addToast({
                        type: "error",
                        message: err.message,
                    })
                }
            }
        },

        clearContact() {
            this.contact = {} as Contact
        },

        async unsubscribeContact(token: string, contactId: string) {
            try {
                await this.contactService.unsubscribeContact(token, contactId)
            } catch (error) {
                const err = error as GgmsError
                if (err.code === "ValidationError") {
                    this.toastService.addToast({
                        type: "error",
                        message: err.message,
                    })
                }
            }
        },

        async checkContactList(typename: string, contactIds: string[]) {
            try {
                return this.contactService.checkContactList(typename, contactIds)
            } catch (error) {
                const err = error as GgmsError
                if (err.code === "ValidationError") {
                    this.toastService.addToast({
                        type: "error",
                        message: err.message,
                    })
                }
            }
        },
    },
})
