import { BaseModel } from "@/shared/models/base-model"

export enum TagType {
    default = "",
    agency = "agency",
    contact = "contact",
    task = "task",
    messageTemplate = "message-template",
    messaging = "messaging",
    upload = "upload",
    tag = "tag",
    origin = "origin",
    form = "form",
    listing = "listing",
}

export interface Tag extends BaseModel {
    displayName: string
    typeName: TagType
    isCustom: boolean
    foregroundColor: string
    backgroundColor: string
    usages?: number
}

export interface CachedTags {
    [key: string]: Tag
}

export interface BulkUpdateTags {
    ids: string[]
    all?: boolean
    updates: {
        toAdd: Tag[]
        toRemove: Tag[]
    }
}
