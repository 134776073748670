import { FiltersGroup } from "./filter"
import { Component, FunctionalComponent } from "vue"
import { Agent } from "./agent"
import { Collection } from "./collection"
import { Message } from "./message"
import { Template } from "./template"
import { Tag } from "./tag"
import { EmailPayload } from "./email"
import { Recipient } from "./recipient"
import { Form } from "./form"
import { Attribute } from "./attributes"

export enum WorkflowStatus {
    unpublished = "unpublished",
    on = "on",
    off = "off",
}
export interface Workflow {
    _id?: string
    name?: string
    description?: string
    nodes: Node[]
    edges: Edge[]
    status?: WorkflowStatus
    publishedStatus?: boolean
    issues?: number
    lastRunAt?: Date
    updatedAt?: Date
    createdBy?: {
        _id?: string
        fullName?: string
    }
    ownedById?: string
    enrolledFrom: string[]
}

export enum NodeType {
    trigger = "trigger",
    new = "new",
    action = "action",
    singleBranch = "singleBranch",
    multipleBranch = "multipleBranch",
    property = "property",
    propertyValue = "propertyValue",
    note = "note",
    contactDistribution = "contactDistribution",
    multipleBranches = "multipleBranches",
    percentage = "percentage",
    calendar = "calendar",
    tags = "tags",
    sendTextMessage = "sendTextMessage",
    sendEmail = "sendEmail",
    enrollOrUnenroll = "enrollOrUnenroll",
    deleteContact = "deleteContact",
    createTask = "createTask",
}

export enum EdgeType {
    custom = "custom",
    smoothstep = "smoothstep",
}
export enum CalendarNodeType {
    calendar = "calendar",
    date = "date",
    time = "time",
    weekDay = "weekDay",
    timeOfDay = "timeOfDay",
}

export enum ContactDistributionNodeType {
    agent = "agent",
    list = "list",
}

export enum SendTextMessageNodeType {
    custom = "custom",
    template = "template",
    containsTags = "containsTags",
    listOfSavedTexts = "listOfSavedTexts",
}

export enum SendEmailNodeType {
    custom = "customEmail",
    template = "templateEmail",
    containsTags = "containsTagsEmail",
    listOfSavedTexts = "listOfSavedTextsEmail",
}

export enum TriggerNodeType {
    contactActivity = "contactActivity",
    schedule = "schedule",
    listingActivity = "listingActivity",
}

export enum ScheduleTriggerNodeType {
    calendar = "calendar",
    weekDay = "weekDay",
    timeOfDay = "timeOfDay",
}

export enum ContactActivityTriggerNodeType {
    membershipChanged = "membershipChanged",
    propertyValueChanged = "propertyValueChanged",
    callStarted = "callStarted",
    callEnded = "callEnded",
    submission = "submission",
    view = "view",
    enrolledInWorkflow = "enrolledInWorkflow",
    unenrollFromWorkflow = "unenrollFromWorkflow",
    contactCreated = "contactCreated",
}

export enum ListingActivityTriggerNodeType {
    appointmentCreated = "appointmentCreated",
    appointmentCreatedAgency = "appointmentCreatedAgency",
}

export enum DistributionType {
    sequential = "sequential",
    random = "random",
    round = "round",
    first = "first",
}
export enum ActionNodeType {
    delay = "delay",
    branch = "branch",
    sendTextMessage = "sendTextMessage",
    sendEmail = "sendEmail",
    propertyValue = "propertyValue",
    contactDistribution = "contactDistribution",
    note = "note",
    tags = "tags",
    enrollOrUnenroll = "enrollOrUnenrol",
    deleteContact = "deleteContact",
    createTask = "createTask",
}

export enum SendEmailToType {
    enrolled = "enrolled",
    associated = "associated",
    specificEmail = "specificEmail",
    specificUsers = "specificUsers",
}

export interface Node {
    id: string
    position: { x: number; y: number }
    type: NodeType | TriggerNodeType
    nextNodeId?: string
    nextEdgeId?: string
    data?: {
        entityType?: EntityTypes
        end?: boolean
        title?: string
        description?: string
        none?: boolean
        disabled?: boolean
        small?: boolean
        filters?: FiltersGroup[]
        newTrigger?: boolean
        toolbarVisible?: boolean
        note?: { text?: string; lastEdited?: string }
        isPinned?: boolean
        firstEvent?: string
        attribute?: Attribute
        copyToAttribute?: Attribute
        branches?: {
            id?: string
            value: string | number
            label?: string
            filtersGroups?: FiltersGroup[]
            selectedFilterGroup?: number
            selectedFilterIndex?: number
            currentStep?: string | undefined
            filterIndex?: number
            selectedFilter?: {
                field: string
                type: string
                rule: string
                value: string
                displayName: string
            }
        }[]
        from?: { name: string; number?: string; country?: string; email?: { emailAddress: string } }
        noneBranch?: { id?: string; value: string }
        showDeleteButton?: boolean
        icon?: Component
        type?: string
        selectedData?:
            | Agent[]
            | Collection[]
            | Template[]
            | string[]
            | Form[]
            | Workflow[]
            | { _id?: string; name: string; label?: string }[]
        emailSendingTypes?: string[]
        specificUsers?: Recipient[]
        specificEmail?: string
        toAdd?: Partial<Tag>[] | Partial<Collection>[] | Partial<Workflow>[]
        toRemove?: Partial<Tag>[] | Partial<Collection>[] | Partial<Workflow>[]
        property?: string
        event?: string
        days?: string[] | number
        hours?: number
        minutes?: number
        firstValue?: string
        secondEvent?: string
        secondValue?: string
        thirdValue?: string
        text?: string
        time?: { days?: number; hours?: number; minutes?: number } | string
        value?: string
        distributionMechanic?: string
        messagePayload?: Message | EmailPayload
        randomise?: boolean
        integration?: {
            _id: string
            integrationName: string
            integrationType: string
        }
        tagsAction?: string
        enrolledFrom?: string[]
        schedule?: string
        scheduleTime?: string
        taskData?: any
        isoDate?: string
    }
}

export interface Edge {
    id: string
    type: string
    target: string
    source: string
}

export interface WorkflowUpdateActionEvent extends Event {
    componentName?: string
    icon?: FunctionalComponent
    title?: string
    description?: string
    node: NodeType | TriggerNodeType
}

export class WorkflowWidget {
    _id?: string
    contactId?: string
    workflowId?: string
    status?: WorkflowWidgetStatus
    name?: string
    nrRuns?: number
    previousNode?: Node
    upcomingNode?: Node
    paused?: boolean
    enrolled?: boolean
    lastRunAt?: Date
    error?: string
}

export enum WorkflowWidgetStatus {
    inProgress = "inProgress",
    completed = "completed",
    missed = "missed",
}

export enum EntityTypes {
    agency = "agency",
    agent = "agent",
    contact = "contact",
    tag = "tag",
    origin = "origin",
    task = "task",
    taskSession = "task-session",
    form = "form",
    collection = "collection",
    publisher = "publisher",
    apiKey = "apiKey",
    call = "call",
    emailMessaging = "emailMessaging",
    smsMessaging = "smsMessaging",
    themes = "themes",
    template = "template",
    job = "job",
    log = "log",
    listing = "listing",
    workflow = "workflow",
    mls = "mls",
    widgetView = "widgetView",
    propertyGroupLayout = "propertyGroupLayout",
    property = "property",
}
