<template>
    <GgmsModal :open="props.open" @closeModal="closeModal" :style="{ 'z-index': 99999 }">
        <!-- Header with Icon -->
        <template #header>
            <div class="flex items-center space-x-4 p-4">
                <div class="flex items-center justify-center h-12 w-12 rounded-full bg-primary-color-100">
                    <DocumentTextIcon class="h-6 w-6 text-primary-color-600" />
                </div>
                <h3 class="text-lg font-medium text-gray-900">Select Merge Field</h3>
            </div>
        </template>

        <!-- Search and Options List -->
        <div class="flex flex-col space-y-4 px-4 py-2">
            <!-- Search Field -->
            <input
                v-model="searchQuery"
                type="text"
                placeholder="Search values"
                class="p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-primary-color-300"
            />

            <!-- Options List -->
            <div class="max-h-72 overflow-y-auto border border-primary-color-300 rounded-lg">
                <ul class="h-64" overflow-y-auto>
                    <li v-for="option in filteredOptions" :key="option.value" class="py-3 px-4">
                        <button
                            class="text-left w-full text-gray-900 font-medium bg-transparent hover:bg-primary-color-100 rounded p-1 focus:outline-none"
                            @click="handleOptionClick(option)"
                        >
                            {{ option.name }}
                        </button>
                    </li>
                </ul>
            </div>
        </div>

        <!-- Footer with Cancel Button -->
        <template #under>
            <div class="flex justify-between space-x-4 p-4">
                <div class="flex justify-start">
                    <GgmsButton v-if="navigationPath.length" styleType="secondary" @click="goBack"
                        >&larr; Back</GgmsButton
                    >
                </div>
                <div class="flex justify-end flex-grow">
                    <GgmsButton styleType="secondary" @click="closeModal">Cancel</GgmsButton>
                </div>
            </div>
        </template>
    </GgmsModal>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from "vue"
import GgmsModal from "@/components/GgmsModal.vue"
import GgmsButton from "@/components/GgmsButton.vue"
import { capitalize, toPascalCase } from "@/shared/utils/helpers"
import { useConfigStore } from "@/stores/config"
import { useFormStore } from "@/stores/form"
import { Prototype } from "@/shared/models/config"
import { Attribute } from "@/shared/models/attributes"
import { CascadeChildOption } from "@/shared/models/cascade-option"
import { DocumentTextIcon } from "@heroicons/vue/outline"
import router from "@/router"

// Props
interface Props {
    open: boolean
    isForRichEditor?: boolean
    specialVariable?: string
    isTemplateTypeEmail?: boolean
}

const props = defineProps<Props>()
const emit = defineEmits(["closeModal", "addMergeField"])

// Stores
const configStore = useConfigStore()
const formStore = useFormStore()

// States
const searchQuery = ref("")
const navigationPath = ref<{ name: string; options: CascadeChildOption[] }[]>([])
const currentOptions = ref<CascadeChildOption[]>([])

// Filtered options based on search query
const filteredOptions = computed(() => {
    return currentOptions.value.filter((option) => option.name.toLowerCase().includes(searchQuery.value.toLowerCase()))
})

// Generate options hierarchy
function generateOptions() {
    const mergeFields = categories.value
        .map((category) => ({
            name: capitalize(category.typeName),
            value: category.typeName,
            children: setChildElements(category),
        }))
        .sort((a, b) => a.name.localeCompare(b.name))

    if (props.isTemplateTypeEmail) {
        mergeFields.push({
            name: "System",
            value: "system",
            children: Object.values(systemVariables),
        })
    }

    if (props.specialVariable) {
        mergeFields.push({
            name: "User",
            value: "user",
            children: setSpecialVariables(),
        })
    }

    return mergeFields
}

const isWorkflowsRoute = computed(() => router.currentRoute.value.path.includes("workflows"))

// Categories for options
const categories = computed(() => {
    let prototypeNames = ["contact", "agent", "agency"]
    if (isWorkflowsRoute.value) {
        prototypeNames = ["contact", "agent", "agency", "form", "call"]
    }
    return configStore.prototypes.filter((prototype) => prototypeNames.includes(prototype.typeName))
})

// Define system variables and special variables
const systemVariables = {
    Unsubscribed: { name: "Unsubscribe Link", value: "{unsubscribeLink}", hasFallback: false },
}

const specialVariableMap = {
    "Welcome user": { name: "Verify Email URL", value: "user.verifyEmailUrl", hasFallback: false },
    "Forgotten password": { name: "Reset Password URL", value: "user.resetPasswordUrl", hasFallback: false },
    "SMS daily quota reached": {
        name: "Setup Business Profile URL",
        value: "user.setupBusinessProfileURL",
        hasFallback: false,
    },
    "Contact import finished": [
        { name: "Contact Import Status", value: "user.status", hasFallback: false },
        { name: "Number of inserted contacts", value: "user.inserted", hasFallback: false },
        { name: "Errors", value: "user.errors", hasFallback: false },
    ],
    "Invite agent": { name: "Invite Link", value: "user.inviteLink", hasFallback: false },
}

// Fetch forms from the store
const forms = computed(() => formStore.tableState.data)

// Function to set child elements based on category
function setChildElements(category: Prototype) {
    const children =
        category.attributes
            ?.map((attribute: Attribute) => ({
                name: attribute.displayName,
                value: `${category.typeName}.${attribute.name}`,
                hasFallback: true,
            }))
            .sort((a, b) => a.name.localeCompare(b.name)) ?? []

    if (category.typeName === "agent") {
        children.unshift({ name: "Signature", value: "{templateRenderedSignature}", hasFallback: false })
    }

    if (category.typeName === "form") {
        children.splice(0, children.length)

        forms.value.forEach((form) => {
            children.push({
                name: form.title,
                value: `form.${form.title}`,
                hasFallback: false,
                children: form.sections.flatMap((section) =>
                    section.elements.flatMap((element) =>
                        element.labels.map((label) => ({
                            name: label,
                            value: `form.${toPascalCase(form.title)}.${element.name}`,
                            hasFallback: false,
                        }))
                    )
                ),
            })
        })
    }

    return children
}

// Function to generate special variables
function setSpecialVariables() {
    const specialVariable = specialVariableMap[props.specialVariable]
    return Array.isArray(specialVariable) ? specialVariable : [specialVariable]
}

// Handle option click
function handleOptionClick(option: CascadeChildOption) {
    if (option.children) {
        navigationPath.value.push({ name: option.name, options: currentOptions.value })
        currentOptions.value = option.children
        searchQuery.value = ""
    } else {
        // Add placeholder fallback value
        const fallbackPlaceholder = "<fallback>"
        emit("addMergeField", `{{${option.value}:${fallbackPlaceholder}}}`)
        closeModal()
        searchQuery.value = ""
    }
}

// Handle back navigation
function goBack() {
    const lastPath = navigationPath.value.pop()
    if (lastPath) currentOptions.value = lastPath.options
    searchQuery.value = ""
}

// Close modal
function closeModal() {
    emit("closeModal")
    setTimeout(() => {
        navigationPath.value = []
        currentOptions.value = generateOptions()
    }, 300)
    searchQuery.value = ""
}

// On mount, generate options
onMounted(async () => {
    if (formStore.tableState.data.length === 0) {
        await formStore.getForms()
    }
    currentOptions.value = generateOptions()
})
</script>
