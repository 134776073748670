<template>
    <div class="flex flex-col gap-y-5">
        <div class="flex max-sm:flex-wrap gap-2">
            <ActionCard
                title="Delay"
                @click="
                    changeAction({
                        title: 'Configure delay...',
                        sidebarComponent: DelaySidebarComponent,
                        type: ActionNodeType.delay,
                    })
                "
            >
                <ClockIcon class="h-5 w-5 text-gray-900"> </ClockIcon>
            </ActionCard>
            <ActionCard title="Branch" :disabled="workflowStore.disabledBranches" @click="enterBranches">
                <MergeIcon :class="iconStyle"> </MergeIcon>
            </ActionCard>
            <!--            <ActionCard title="Go to workflow" @click="changeAction({ title: 'Go to workflow...' })">-->
            <!--                <ArrowCircleRightIcon class="h-5 w-5 text-gray-900"> </ArrowCircleRightIcon>-->
            <!--            </ActionCard>-->
            <!--            <ActionCard title="Go to action" @click="changeAction({ title: 'Go to action...' })">-->
            <!--                <ArrowCircleRightIcon class="h-5 w-5 text-gray-900"> </ArrowCircleRightIcon>-->
            <!--            </ActionCard>-->
        </div>
        <GgmsInput
            input-class="bg-gray-100 border-0"
            v-model="searchTerm"
            :small="true"
            :iconEnd="SearchIcon"
            placeholder="Search"
        ></GgmsInput>
        <div class="flex flex-col">
            <div v-if="filteredCommunicationItems.length">
                <div class="flex gap-x-2 cursor-pointer" @click="isOpenCommunication = !isOpenCommunication">
                    <GreenMailIcon />
                    <span class="text-base leading-6 font-semibold text-gray-700">Communications</span>
                    <ArrowDown v-if="isOpenCommunication" class="fill-gray-700"></ArrowDown>
                    <ArrowDown v-else class="rotate-180 fill-gray-700"></ArrowDown>
                </div>
                <transition>
                    <div v-if="isOpenCommunication" class="flex flex-col pt-3">
                        <ActionItem
                            v-for="item in filteredCommunicationItems"
                            :key="item.title"
                            :title="item.title"
                            color="teal-600"
                            :description="item.description"
                            @click="changeAction(item)"
                        ></ActionItem>
                    </div>
                </transition>
            </div>
            <div v-if="filteredCrmItems.length">
                <div class="flex gap-x-2 mt-6 cursor-pointer" @click="isOpenCrm = !isOpenCrm">
                    <BlueUsersIcon />
                    <span class="text-base leading-6 font-semibold text-gray-700">CRM</span>
                    <ArrowDown v-if="isOpenCrm" class="fill-gray-700"></ArrowDown>
                    <ArrowDown v-else class="rotate-180 fill-gray-700"></ArrowDown>
                </div>
                <transition>
                    <div v-if="isOpenCrm" class="flex flex-col pt-3">
                        <ActionItem
                            v-for="item in filteredCrmItems"
                            :key="item.title"
                            :title="item.title"
                            color="blue-600"
                            :description="item.description"
                            @click="changeAction(item)"
                        ></ActionItem>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { Component, computed, ref } from "vue"
import { ClockIcon, SearchIcon } from "@heroicons/vue/outline"
import ArrowDown from "@/components/icons/ArrowDown.vue"
import ActionCard from "@/components/workflows/sidebar/action/ActionCard.vue"
import ActionItem from "@/components/workflows/sidebar/action/ActionItem.vue"
import MergeIcon from "@/components/icons/MergeIcon.vue"
import GgmsInput from "@/components/GgmsInput.vue"
import GreenMailIcon from "@/components/workflows/icons/GreenMailIcon.vue"
import BlueUsersIcon from "@/components/workflows/icons/BlueUsersIcon.vue"
import NoteComponent from "@/components/workflows/sidebar/action/crm/NoteComponent.vue"
import PropertyValueComponent from "@/components/workflows/sidebar/action/crm/PropertyValueComponent.vue"
import ContactDistributionComponent from "@/components/workflows/sidebar/action/crm/ContactDistributionComponent.vue"
import SendEmailComponent from "@/components/workflows/sidebar/action/communications/SendEmailComponent.vue"
import BranchSidebarComponent from "@/components/workflows/sidebar/BranchSidebarComponent.vue"
import DelaySidebarComponent from "@/components/workflows/sidebar/DelaySidebarComponent.vue"
import SendTextMessageComponent from "@/components/workflows/sidebar/action/communications/SendTextMessageComponent.vue"
import ApplyTagsComponent from "@/components/workflows/sidebar/action/crm/ApplyTagsComponent.vue"
import { useWorkflowStore } from "@/stores/workflow"
import { ActionNodeType } from "@/shared/models/workflow"
import EnrollOrUnenrollWorkflowComponent from "@/components/workflows/sidebar/action/crm/EnrollOrUnenrollWorkflowComponent.vue"
import DeleteContactsComponent from "./action/crm/DeleteContactsComponent.vue"
import CreateTaskSidebarComponent from "@/components/workflows/sidebar/action/crm/CreateTaskSidebarComponent.vue"

const workflowStore = useWorkflowStore()

const emit = defineEmits(["changeAction"])

const isOpenCommunication = ref(true)
const isOpenCrm = ref(true)

const communicationItems = [
    {
        title: "Send email",
        description: "Send an email to contacts",
        sidebarComponent: SendEmailComponent,
    },
    {
        title: "Send text message",
        description: "Send a text message to contacts",
        sidebarComponent: SendTextMessageComponent,
    },
    // {
    //     title: "Send in-app notification (Internal)",
    //     description: "Send a notification to any of your team members",
    //     sidebarComponent: BranchSidebarComponent,
    // },
    {
        title: "Communication Delay",
        description: "Send an email to any of your team members",
        sidebarComponent: BranchSidebarComponent,
    },
]

const crmItems = [
    {
        title: "Property value",
        description: "Set, clear, copy",
        sidebarComponent: PropertyValueComponent,
    },
    {
        title: "Contact Assignment",
        description: "Assigning, Availability",
        sidebarComponent: ContactDistributionComponent,
    },
    {
        title: "Note",
        description: "Description of item",
        sidebarComponent: NoteComponent,
    },
    {
        title: "Create task",
        description: "Create a task for an agent",
        sidebarComponent: CreateTaskSidebarComponent,
    },
    {
        title: "Enroll or Unenroll from workflows",
        description: "Configure action...",
        sidebarComponent: EnrollOrUnenrollWorkflowComponent,
    },
    // {
    //     title: "Create record",
    //     description: "Description of item",
    //     sidebarComponent: DelaySidebarComponent,
    // },
    {
        title: "Delete contact",
        description: "Description of item",
        sidebarComponent: DeleteContactsComponent,
    },
    {
        title: "Manage communication subscriptions",
        description: "Description of item",
        sidebarComponent: BranchSidebarComponent,
    },
    // {
    //     title: "Contact owner",
    //     description: "Description of item",
    //     sidebarComponent: BranchSidebarComponent,
    // },
    {
        title: "Add or remove tags",
        description: "Add, remove, edit",
        sidebarComponent: ApplyTagsComponent,
    },
    {
        title: "Property Alert(s)",
        description: "Description of item",
        sidebarComponent: BranchSidebarComponent,
    },
    // {
    //     title: "Suggest Property",
    //     description: "Description of item",
    //     sidebarComponent: BranchSidebarComponent,
    // },
]

const searchTerm = ref("")

const filteredCommunicationItems = computed(() =>
    communicationItems.filter((item) => item.title.toLowerCase().includes(searchTerm.value.toLowerCase()))
)

const filteredCrmItems = computed(() =>
    crmItems.filter((item) => item.title.toLowerCase().includes(searchTerm.value.toLowerCase()))
)

const iconStyle = computed(() => [workflowStore.disabledBranches ? "fill-gray-400" : "", "h-5 w-5 rotate-180"])

function enterBranches() {
    if (workflowStore.disabledBranches) {
        return
    }
    changeAction({
        title: "Configure branch...",
        sidebarComponent: BranchSidebarComponent,
        type: ActionNodeType.branch,
    })
}
function changeAction(event: { title: string; sidebarComponent?: Component; description?: string; type: string }) {
    emit("changeAction", event)
}
</script>
